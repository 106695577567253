import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
Vue.prototype.$axios = axios;

// import AlertComponent from "./components/alertComponent";

import "./assets/styles/common/common.scss";
import "./assets/styles/common/animation.scss";
import "./assets/styles/common/functions.scss";
import "./assets/styles/srCommon.scss";

// Vue.component("Alert", AlertComponent);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
