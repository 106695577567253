<template>
  <div id="app" class="wh">
    <!-- <router-view /> -->
    <HomeView v-if="environment"></HomeView>
    <!-- 版本过低提示 -->
    <div class="copyAlert wh pa" v-if="version">
      <div class="textm gl-pa-center">
        <div class="w text">当前版本过低，建议</div>
        <div class="w text2">升级到最新版本</div>
        <div class="define cuosor" @click="define">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeView from "./views/Home.vue";
import { getHashString } from "./utils";
export default {
  data() {
    return { environment: false, version: false };
  },
  components: { HomeView },
  mounted() {},
  created() {
    var that = this;
    console.log("url:", location.href);
    // wx
    if (window.wx) {
      // 在微信小程序环境下检测token是否存在, 用于判断用户是否登录
      this.checkToken();

      window.addEventListener("popstate", (event) => {
        console.log("hash changed");

        // hash 变化后重新检测token是否存在,
        const tokenStatus = this.checkToken();

        this.environment = true;
        // 如果token是以 地址栏hash的方式存在, 那就调用返回, 避免返回按钮二次变化情况

        if (tokenStatus == 1) {
          console.log("token status is 1 ,  history go -1");
          setTimeout(() => {
            window.history.go(-1);
          });
        }
      });
    }
    // app 检验版本号
    if (window.clientJsBridge) {
      this.environment = true;
      window.clientJsBridge.call(
        { path: "jsp://com.huawei.phoneservice/application/appVersionCode" },
        function (result) {
          console.info("result: " + result);
          // 版本号大于等于1301001100，拉起登录
          if (result >= 1301001100) {
            that.version = false;
            that.ifLogin();
          } else {
            // 版本号小于1301001100，是旧版本，需要升级app
            that.version = true;
          }
        },
        function (status) {
          console.info("error: " + status);
        }
      );
    }
    // # ########## test end
  },
  methods: {
    // app是否登陆接口地址
    ifLogin() {
      if (window.clientJsBridge) {
        console.log(window.clientJsBridge);
        window.clientJsBridge.call(
          {
            path: "jsp://com.huawei.phoneservice/commonBusiness/isLogin", // app是否登陆接口地址
            isNeedLoginAccount: true, // 是否需要拉起登陆，false：不需要，true：需要
          },
          function (result) {
            //  调用成功回调，result 返回boolean类型，true代表已登录，false代表未登录
            console.log("result: " + result);
          },
          function (status) {
            // 调用失败回调，返回状态码，状态码详见下表
            console.log("error: " + status);
          }
        );
      }
    },
    /**     * 启动时检测token是否存在     */
    checkToken() {
      const sessionToken = window.sessionStorage.getItem("token");
      const tokenInHash = getHashString("token");
      if (tokenInHash) {
        console.log("hash token is :", tokenInHash);
        window.sessionStorage.setItem("token", tokenInHash);
        // token 存在 hash中
        return 1;
      } else if (sessionToken) {
        console.log("history token is :", sessionToken);
        // token 存在seession 中
        return 2;
      } else {
        console.log("empty token, to login");
        const url = `/pageWxLogin/pages/wxLogin?invalidRT=1`; // 华为正式环境的登录页面地址
        // const url = `/pages/login/index`; // 测试环境下的登录页面地址
        window.wx.miniProgram.navigateTo({
          url,
        });

        // token 不存在
        return 0;
      }
    },
    define() {
      var androidLinkurl =
        "https://appgallery.cloud.huawei.com/appDetail?pkgName=com.huawei.phoneservice&channelId=shop&referrer=shop&detailType=0&callType=AGDLINK";
      var a = document.createElement("a");
      a.setAttribute("href", androidLinkurl); //href链接
      a.click();
    },
  },
};
// 禁止缩放
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>

<style lang="scss">
@import "./assets/styles/common/functions";
#app {
  margin: 0 auto;
  .copyAlert {
    z-index: 200;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    text-align: center;
    .textm {
      white-space: nowrap;
      padding: px2(40);
      text-align: center;
      letter-spacing: px2(5);
      font-size: px2(30);
      color: #000000;
      background-color: rgba($color: #fff, $alpha: 0.8);
      border-radius: px2(10);
    }
    .text2 {
      margin-top: px2(20);
    }
    .define {
      font-size: px2(30);
      margin-top: px2(40);
      display: inline-block;
      padding: px2(20) px2(50);
      border-radius: px2(40);
      border: px2(1) solid #9c9c9c;
    }
  }
}
</style>
