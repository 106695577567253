<template>
  <div class="homeBox wh pr">
    <div class="sty3 sty_am gl-pa-center" v-show="pageStatus == 3"></div>
    <div class="sty3 sty_am_sty gl-pa-center" v-show="pageStatus == 3"></div>

    <div class="containerBox gl-pa-center">
      <!-- <div class="nei_qu gl-pa-center"></div> -->
      <div class="dsas gl-pa-center" v-show="pageStatus != 3">
        <div
          class="gl-pa-center xian"
          :class="play_Status || pageStatus == 2 ? 'gai' : ''"
        >
          <div class="record-box yuanD gl-pa-center opa">
            <div
              class="record-card card1"
              :class="{ show: pageStatus == 1 }"
            ></div>
            <div
              class="record-card card2"
              :class="{ show: pageStatus == 2 }"
            ></div>
            <div class="dian gl-pa-center sty_am"></div>
          </div>
        </div>
      </div>
      <div class="yuanQiu a-fadein" v-show="pageStatus == 2"></div>
      <!-- page1 s-->
      <div class="page1 wh pr" v-show="pageStatus == 1">
        <div class="sty gl-pa-center sty_am"></div>
        <div class="quan"></div>
        <div class="quan two"></div>

        <div class="gl-pa-center muBox" v-if="!reminder">
          <div class="icon_music pa"></div>
          <div class="icon_music m_2 pa"></div>
          <div class="icon_music m_3 pa"></div>
        </div>

        <div class="loadingSum gl-pa-center">{{ load }}%</div>
        <div class="me_z pa"></div>
        <!-- 弹窗 温馨提示 -->
        <div class="reminder wh pa a-fadein" v-if="reminder">
          <div class="re_Box gl-pa-center">
            <div class="re_title">
              关于【华为旗舰店·音频<br />体验区】隐私的声明及使用须知
            </div>
            <div class="re_cont">
              <div class="re_de">
                更新日期： 2022年12月23日
                【华为旗舰店·音频体验区音频创作分享】是由【华为】为您提供的创作分享服务。华为非常重视您的个人信息和隐私保护，我们将会按照法律要求和业界成熟的安全标准，为您的个人信息提供相应的安全保护措施。
              </div>
              <div class="re_die">
                <span> 1.我们如何收集和使用您的个人信息</span><br />
                个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
                个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。您的个人敏感信息我们将采用加粗显著标识。
                我们仅会出于本声明所述的以下目的，收集和使用您的个人信息，当我们要将信息用于本声明未载明的其它用途时，会事先征求您的同意。<br />
                <span>个人信息类型：</span>
                当扫描二维码在线试听您所创作的音频时，若通过”我的华为“APP扫描，则将获取您的会员信息自动登录，随即进入试听界面。如未注册会员，将需完成注册后登录，即可体验试听功能；
                若通过微信扫描，则自动唤起“我的华为”小程序并获取您的会员信息自动登录，随即进入试听界面。如未注册会员，将需完成注册后登录，即可体验试听功能；
                <span>个人信息收集处理目的：</span
                >我们收集上述信息是为了帮助您顺利完成在线试听体验。
              </div>
              <div class="re_die">
                <span>2. 对未成年人的保护</span><br />
                如果您是未成年人，则需要您的监护人同意您参与本活动并同意相关活动条款。父母和监护人也应采取适当的预防措施来保护未成年人，包括监督其参与本活动的行为。
              </div>
              <div class="re_die">
                <span>3. 管理您的数据</span><br />
                如您要行使您的数据主体权利（访问、更正或删除您的个人信息）或存在任何疑问、意见或建议，请通过本声明第五部分“如何联系我们”的联系方式与我们取得联系并行使您的相关权利。
                因我们的服务无需注册账号即可使用，因此没有提供需要匹配的账号注销功能。
              </div>
              <div class="re_die">
                <span>4. 数据存储地点及期限</span><br />
                （一）数据存储地点
                我们在本服务项下收集和产生的个人信息，将存储在中国。我们将采用必要的技术手段如匿名和加密以及符合业界标准的安全防护措施以保护您的个人信息安全，防止您的个人信息被不当使用或未经授权的访问、修改、损坏、丢失或泄露。
                （二）数据存储期限
                我们仅为实现目的所必需的时间保留您的个人信息，并在超出前述保留时间后删除或匿名化处理您的个人信息，但法律法规另有要求的除外。 
                您的创作音频我们将储存在特定的华为云服务器内，未经允许我们不会将您的创作用于其他用途。音频源文件将在创作之日起，30天后删除。
              </div>
              <div class="re_die">
                <span>5. 如何联系我们</span><br />
                我们设立了个人信息保护专职部门（或个人信息保护专员），您可以通过访问https://cons<br />umer.huawei.com/cn/legal/privacy-quest<br />ions/与其取得联系，我们会在15个工作日内尽快回复。
                如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过向有管辖权法院提起诉讼、向行业自律协会或政府相关管理机构投诉等外部途径进行解决。如果我们未能圆满解决您的隐私或数据使用问题，请点击以下网址联系我们的第三方争端解决机构(免费):https://<br />feedback-form.truste.com/watchdog/req<br />uest。
                华为将始终遵照我们的隐私政策来收集和使用您的信息。有关我们的隐私政策，可参阅华为消费者业务隐私声明。
              </div>
            </div>
            <div class="btnBox w pa">
              <div class="yes" @click="noagree">不同意</div>
              <div class="no" @click="agree">同意</div>
            </div>
          </div>
        </div>
      </div>
      <!-- page1 e-->

      <!-- page2 s-->
      <div class="page2 wh pr a-fadein" v-show="pageStatus == 2">
        <div class="sty gl-pa-center sty_am"></div>
        <div class="code pa">创作者编号：{{ codeMath }}</div>
        <div class="textBox">
          <div class="text_ti"></div>
          <div class="mid">
            我在华为旗舰店的听音室实现了创作梦，<br />听听我的创作吧！
          </div>
        </div>
        <div class="cont">
          <div
            class="play_icon"
            :class="play_Status ? 'on_' : 'play_icon'"
            @click="playFun"
          ></div>
          <div class="progress pr">
            <div class="pa prog h" :style="{ width: regSum + '%' }"></div>
          </div>
        </div>

        <div v-show="play_Status" class="music_icon2 pa musicp2"></div>
        <div v-show="play_Status" class="music_icon2 two pa musicp3"></div>
        <div v-show="play_Status" class="music_icon2 three pa musicp2"></div>
        <div class="btn_p2 w pa">
          <div class="gocl" @click="toAddED(3)">即刻前往</div>
          <div class="down" @click="copyUrl(musicUrl)">下载</div>
        </div>
        <!-- 复制提醒弹窗 -->
        <div class="copyAlert wh pa" v-if="capyAlert">
          <div class="textm gl-pa-center">
            <div class="gl-pa-center w">
              已复制链接,请打开自<br />带浏览器粘贴下载
            </div>
          </div>
        </div>
      </div>
      <!-- page2 e-->

      <!-- page3 s-->

      <div class="page3 wh a-fadein pr" v-show="pageStatus == 3">
        <div class="navBox">
          <div class="back-icon pa" @click="clickFun(2, true)"></div>
          <div>活动地址</div>
        </div>
        <div class="shp"></div>
        <div class="addiss">
          <div class="adisName">华为旗舰店 · 音频体验区</div>
          <div class="adDate">营业时间： 10:00-22:00</div>
        </div>
        <div class="map pr">
          <div class="map_name">华为旗舰店 · 武汉梦时代广场</div>
          <div class="map_add">
            门店地址：武汉市武昌区武珞路598号武商梦时代广场B区一层及二层（铺位号B-101、B-201、B-237）
          </div>
          <div class="navigation pa" @click="toMap">
            <div class="gps-icon"></div>
            <div class="nav_na">导航</div>
          </div>
        </div>
        <div class="map top_ pr">
          <div class="map_name">华为旗舰店 · 天津滨江道</div>
          <div class="map_add">
            门店地址：天津市和平区和平路239-243号商厦1-2层原交通饭店大楼
          </div>
          <div class="navigation pa" @click="toMap2">
            <div class="gps-icon"></div>
            <div class="nav_na">导航</div>
          </div>
        </div>
        <div class="map top_ pr">
          <div class="map_name">华为旗舰店 · 北京王府井</div>
          <div class="map_add">
            门店地址：北京市东城区王府井大街88号1幢地上一层L1016号、地上二层L2003、L2016、L2017号铺位
          </div>
          <div class="navigation pa" @click="toMap3">
            <div class="gps-icon"></div>
            <div class="nav_na">导航</div>
          </div>
        </div>
      </div>
      <!-- page3 e -->
    </div>

    <audio
      v-if="!reminder"
      ref="audio"
      :src="musicUrl"
      @loadedmetadata="oncanplaythrough"
    ></audio>
  </div>
</template>

<script>
import { serverBase } from "./../api/index";
import { getQueryString } from "../utils/index";
// import config from "../../public/config.js";

export default {
  name: "home",
  props: {},
  data() {
    return {
      codeMath: null,
      play_Status: false,
      routerS: false,
      load: 0,
      timer: null,
      autoPlay: null,
      reminder: true,
      pageStatus: 1,
      regSum: 0,
      capyAlert: false,
      currentTime: null,
      musicUrl: "",
      id_is: null,
      muLoadingSum: 0,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        clearInterval(this.timer);
      }
    });
    // 生成随机编号
    this.codeMath = Math.floor(10000 + Math.random() * (21000 - 11000));
    // 获取音乐
    this.getMusic();
  },
  methods: {
    // 不同意
    noagree() {
      if (window.wx) {
        const url = `/pages/index`;
        window.wx.miniProgram.navigateTo({
          url,
        });
      }
      // app
      if (window.clientJsBridge) {
        window.clientJsBridge.call(
          {
            path: "jsp://com.huawei.phoneservice/navigation/finish", // 关闭H5
          },
          function (result) {
            console.log("result: " + result);
          },
          function (status) {
            console.log("error: " + status);
          }
        );
        // window.open("about:blank", "_self");
        // window.close();
      }
    },
    getMusic() {
      let that = this;
      const id = getQueryString("id");
      this.id_is = id;
      console.log("id:", id);
      if (!id) return;
      console.log(serverBase);
      this.$axios
        .get(serverBase + "/musicName?id=" + id)
        .then(function (response) {
          console.log(response);
          if (response.data.code == 0) {
            var name = response.data.result;
            that.musicUrl = serverBase + "/musicShare/" + name;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 音频加载进度
    oncanplaythrough(res) {
      let that = this;
      var maxTime = parseInt(res.target.duration);
      console.log(maxTime);
      if (!this.reminder) {
        if (maxTime < 150) {
          that.muLoadingSum = 50;
        } else if (maxTime > 200) {
          that.muLoadingSum = 15;
        } else if (maxTime > 400) {
          that.muLoadingSum = 10;
        }
        that.loadFun(that.muLoadingSum);
      }
    },
    loadFun(sum) {
      let that = this;
      that.timer = setInterval(() => {
        that.load = Math.floor(Math.random() * (sum - 1)) + this.load;
        if (that.load >= 100) {
          that.load = 100;
          clearInterval(that.timer);
          that.timer = null;
          setTimeout(() => {
            that.clickFun(2);
            that.routerS = true;
            that.play_Status = true;
          }, 1000);
        }
      }, 100);
    },
    // 同意点击事件
    agree() {
      this.reminder = false;
    },

    clickFun(val, status) {
      this.pageStatus = val;
      if (val == 2) {
        // 调取音频播放
        setTimeout(() => {
          this.updateTime();
        }, 100);
      }
      if (status) {
        this.audio.currentTime = this.currentTime;
        this.audio.play();
      }
    },
    toAddED(val) {
      this.pageStatus = val;
    },
    //进度条发生变化时触发
    updateTime() {
      var that = this;
      this.audio = this.$refs.audio;
      if (!this.audio) return false;
      this.audio.pause();
      if (!this.play_Status) return false;

      this.audio.play();
      this.autoPlay = setInterval(() => {
        that.process();
      }, 10);
    },
    process() {
      this.currentTime = parseInt(this.audio.currentTime);
      if (!this.currentTime) return false;
      var current_Time = (this.audio.currentTime / this.audio.duration) * 100;
      // console.log(current_Time);
      this.regSum = current_Time;
      if (this.regSum >= 100) {
        this.audio.pause();
        this.play_Status = false;
        clearInterval(this.autoPlay);
      }
    },
    // 点击播放暂停
    playFun() {
      if (this.regSum == 100) {
        this.audio.currentTime = 0;
        this.audio.play();
      }
      this.play_Status = !this.play_Status;
      this.updateTime();
      if (!this.play_Status) {
        clearInterval(this.autoPlay);
      }
    },
    // 下载
    copyUrl(val) {
      // 模拟 输入框
      var cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效

      document.execCommand("copy");

      this.capyAlert = true;
      setTimeout(() => {
        this.capyAlert = false;
      }, 3000);
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    // 跳转地图
    toMap() {
      var gaodeH5Url = "https://surl.amap.com/DikjltBeGo";
      window.location.href = gaodeH5Url;
    },
    toMap2() {
      var gaodeH5Url = "https://surl.amap.com/1gr55mq71u67g";
      window.location.href = gaodeH5Url;
    },
    // 跳转地图
    toMap3() {
      var gaodeH5Url = "https://surl.amap.com/1fqSjkaL64q3";
      window.location.href = gaodeH5Url;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss" type="text/scss">
@import "./home.scss";
</style>
