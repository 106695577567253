export function getQueryString(name) {
  let value_ = null;
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  if (window.location.search.split("?").length > 1) {
    const r = window.location.search.split("?")[1].match(reg);
    if (r != null) {
      value_ = unescape(r[2]);
    }
  }
  if (!value_) {
    if (window.location.hash.split("?").length > 1) {
      const r = window.location.hash.split("?")[1].match(reg);
      if (r != null) {
        value_ = unescape(r[2]);
      }
    }
  }
  return value_;
}
export function getHashString(name) {
  let value_ = null;
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const hashPureStr = window.location.hash.replace("#", "");
  const hashArr = hashPureStr.split("/");
  if (hashArr.length > 1) {
    const r = hashArr[1].match(reg);
    if (r != null) {
      value_ = unescape(r[2]);
    }
  } else {
    const r = hashArr[0].match(reg);
    if (r != null) {
      value_ = unescape(r[2]);
    }
  }
  return value_;
}
